import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllFarmMappingsWithParams,
  searchAllFarmMappingsWithParams,
  getFarmMappingData,
  // getLoadingFarmMapping,
  getFarmMappingMeta,
} from "features/v1/farmMapping";
import { Table } from "components/table";
import { useDisclosure, useTable } from "hooks";
import { legacyFormatDate } from "utils/v1/tableUtils";
import { Flex } from "organic";
import { Modal, ModalContent } from "components/modal";
import ViewMappingDetails from "./viewMappingDetails";
import EditMappingDetails from "./editMappingDetails";
import { convertObjectToLatLngObject, appGet, formatDate } from "utils";
// import { useGoogleMaps } from "hooks/app";
import { FarmMappingSelect, Select } from "components/select";
import { useLeafletMaps } from "hooks/app/maps/useLeafletMaps";
import { jsonToCSV } from "react-papaparse";
import { getAOSFlavour } from "services/customerService";
import { toast } from "react-toastify";
import _ from "lodash";
import GoogleMap from "components/map/googleMap";
import { LeafletMap, LeafletMarker, LeafletPolygon } from "components/map";
import { nanoid } from "nanoid";
import { VisibilityWrapper } from "components/layout";
import { useAOSFlavour } from "hooks/app/multitenant/useAosFlavour";

const FarmerPopover = ({ mapping }) => {
  return (
    <Flex flexDirection="column" gap="0.25rem">
      <Flex fontWeight="500" textTransform="capitalize" fontSize="1rem" letterSpacing="1.2">
        {appGet(mapping, ["farmer", "first_name"])} {appGet(mapping, ["farmer", "last_name"])}
      </Flex>
      <Flex color="gray600">
        {appGet(mapping, ["farmer", "ta_number"])} ({appGet(mapping, ["crop_type", "name"])})
      </Flex>
      <Flex color="gray400">
        {appGet(mapping, ["farmer", "lga"])}, {appGet(mapping, ["farmer", "state"])}
      </Flex>
    </Flex>
  );
};
const generateMapperCsvFilename = () => {
  const flavour = getAOSFlavour();
  return `${_.upperCase(flavour)}_Mapper_PolygonExport${formatDate(new Date())}.csv`;
};

const MappingOverviewPage = () => {
  const dispatch = useDispatch();

  let farmMappingData = useSelector(getFarmMappingData);
  // let loading = useSelector(getLoadingFarmMapping);
  let farmMappingMeta = useSelector(getFarmMappingMeta);

  const farmMappingModal = useDisclosure();
  const farmMappingEditModal = useDisclosure();

  const { aosFlavour } = useAOSFlavour();

  // const { isMapLoaded, drawPolygon, placeManyMarkers, drawManyPolygons } = useGoogleMaps({
  //   libraries: ["core", "maps", "marker", "geometry", "geocode"],
  // });

  const farmMaps = useMemo(() => {
    if (!farmMappingData?.length) {
      return [];
    }

    const transformedMappingData = farmMappingData
      ?.filter(mapping => mapping?.mappedFarm?.routeCoordinates)
      .map(mapping => ({
        key: mapping._id,
        positions: mapping?.mappedFarm?.routeCoordinates.map(coord =>
          convertObjectToLatLngObject(coord, {
            latKey: "latitude",
            lngKey: "longitude",
          })
        ),
      }));

    return transformedMappingData;
  }, [farmMappingData]);

  const { isMapLoaded, mapRef, flyTo } = useLeafletMaps(farmMappingData);

  const { tableProps, selectedRow: selectedFarmer } = useTable({
    data: farmMappingData,
    columns: [
      {
        Header: "ID",
        accessor: "ta_number",
      },
      {
        id: "mappingCrop",
        Header: "Crop Type",
        accessor: mapping => appGet(mapping, ["crop_type", "name"], "N/A"),
      },
      {
        id: "mappingDate",
        Header: "Date",
        accessor: mapperInfo => legacyFormatDate(mapperInfo?.createed_at),
      },
      // {
      //   id: "mappingAgent",
      //   Header: "Agent",
      //   accessor: mapperInfo => `${mapperInfo?.agent?.first_name || "N/A"} ${mapperInfo?.agent?.last_name || "N/A"}`,
      // },
      {
        id: "mappingFarmer",
        Header: "Farmer",
        accessor: mapperInfo => `${mapperInfo?.farmer?.first_name || "N/A"} ${mapperInfo?.farmer?.last_name || "N/A"}`,
      },
      {
        Header: "Farm Size (ha)",
        accessor: "farm_size",
      },
      {
        id: "mappingAccuracy",
        Header: "Accuracy",
        accessor: mapperInfo => mapperInfo?.coordinate?.accuracy || "N/A",
      },
      {
        Header: "Adjusted Farm Size (ha)",
        accessor: "adjusted_farm_size",
      },
    ],
    title: "All Mappings",
    useServerPagination: true,
    serverPaginationMeta: farmMappingMeta,
    noViewAction: true,
  });

  const handleExportPolygons = ({ format }) => {
    if (format === "csv") {
      const FarmMapsJson = JSON.stringify(
        farmMappingData
          .filter(unfiltered => unfiltered?.mappedFarm)
          .map(mapping => {
            return {
              ...JSON.parse(JSON.stringify(mapping?.mappedFarm)),
              id: mapping.ta_number,
              size: mapping.farm_size,
            };
          })
      );
      const farmMapsCSV = jsonToCSV(FarmMapsJson);
      if (farmMapsCSV && farmMapsCSV.length > 0) {
        // export successfull
        let hiddenElement = document.createElement("a");
        let blob = new Blob(["\ufeff", farmMapsCSV]);
        let url = URL.createObjectURL(blob);
        hiddenElement.href = url;
        // hiddenElement.target = "_blank";)
        hiddenElement.download = generateMapperCsvFilename();
        hiddenElement.click();
        hiddenElement.remove(); // experimental, hopefully prevent creating too many nodes
        toast.success("Maps Polygon Export Successful");
      } else {
        toast.warn("No data for the current filter, try a different filter");
      }
    }
  };

  return (
    <>
      <Flex flexDirection="column" position="relative" my="2rem">
        {/* Leaflet Map */}
        <VisibilityWrapper isVisible={aosFlavour !== "local"}>
          <LeafletMap ref={mapRef}>
            {farmMappingData?.length
              ? farmMappingData
                  .filter(mapping => mapping?.coordinate)
                  .map(mapping => {
                    return (
                      <LeafletMarker
                        key={nanoid()}
                        position={convertObjectToLatLngObject(mapping.coordinate, {
                          latKey: "latitude",
                          lngKey: "longtitude",
                        })}
                        info={<FarmerPopover mapping={mapping} />}
                      />
                    );
                  })
              : null}
            {farmMaps?.length
              ? farmMaps.map(transformedMapping => {
                  return <LeafletPolygon key={transformedMapping.key} positions={transformedMapping.positions} />;
                })
              : null}
          </LeafletMap>
        </VisibilityWrapper>
        {/* Google Map */}
        <VisibilityWrapper isVisible={aosFlavour === "local"}>
          <GoogleMap height={400} width="100%" />
        </VisibilityWrapper>

        <Flex position="absolute" zIndex={999999} right="1rem" gap="1rem" top="1rem" minWidth="360px">
          <FarmMappingSelect
            onChange={farm => {
              console.log({ farm, isMapLoaded });
              // if (isMapLoaded) {
              flyTo(
                convertObjectToLatLngObject(farm.coordinate, {
                  latKey: "latitude",
                  lngKey: "longtitude",
                })
              );
              // }
            }}
          />
          <Select
            options={[{ label: "Export as csv", value: "csv" }]}
            onChange={val => {
              handleExportPolygons({ format: val });
            }}
            width="200px"
            isDisabled
            placeholder="Export Polygons"
          />
        </Flex>
      </Flex>
      {/* </VisibilityWrapper> */}
      {/* <VisibilityWrapper isVisible={!farmMappingData?.length}>
        <EmptyState title="Map View" subtitle="No data to show  on map" />
      </VisibilityWrapper> */}

      {/* Table */}
      <Table
        {...tableProps}
        onViewAction={() => {
          farmMappingModal.onOpen();
        }}
        onDataFilter={filterParams => {
          dispatch(getAllFarmMappingsWithParams({ ...filterParams }));
        }}
        onSearch={(params, { signal }) => {
          console.log("search callback", { text: params.searchText });
          dispatch(searchAllFarmMappingsWithParams(params, { signal }));
        }}
        onEditAction={() => {
          farmMappingEditModal.onOpen();
        }}
      />
      {/* <ViewFarmMappingDetails modal={farmMappingModal} selected={selectedFarmMapping} />
      <EditFarmMappingDetails
        updateFarmMappingInfo={handleUpdateFarmMappingInfo}
        modal={farmMappingModal}
        selected={selectedFarmMapping}
      /> */}
      {/* view farmer */}
      <Modal
        size
        open={farmMappingModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmMappingModal.onClose();
          }
        }}
      >
        <ModalContent title="View Farmer  Details">
          <ViewMappingDetails />
        </ModalContent>
      </Modal>
      {/* edit farmer */}
      <Modal
        open={farmMappingEditModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmMappingEditModal.onClose();
          }
        }}
      >
        <ModalContent title="Edit Farm Mapping Details">
          <EditMappingDetails selected={selectedFarmer} modal={farmMappingEditModal.modal} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default MappingOverviewPage;

import { getAOSFlavour } from "services/customerService";

export const getRegionTerm = () => {
  const flavour = getAOSFlavour();

  switch (flavour) {
    case "aos":
      return "Region";
    case "fida":
      return "Section";
    case "local":
      return "Section"; // NOTE: HI DEV CHANGE ME IF YOU LIKE
    default:
      return "Region";
  }
};

import React, { useEffect, useState } from "react";
import { getAllRegions, getLoadingRegions, getRegionOptions } from "features/v1/regions";
import _ from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getLoadingUser, getUserRegion } from "features/v1/auth";
import { selectOptionFromExtraField, selectOptionFromValue } from "utils/v1/selectUtils";
import { Select, selectConstants } from ".";
import { getRegionTerm } from "utils/flavour-utils";

const regionSelectPropTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  selectMultiple: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

const RegionSelect = ({
  value,
  colorScheme,
  onChange,
  selectMultiple,
  disabled,
  defaultValue,
  name,
  label,
  required,
  isDisabled,
  useAll,
}) => {
  const [permittedRegionOptions, setPermittedRegionOptions] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedRegions, setSelectedRegions] = useState(); // if selectMultiple

  const dispatch = useDispatch();

  const userRegion = useSelector(getUserRegion);
  const loadingUser = useSelector(getLoadingUser);
  const loadingRegions = useSelector(getLoadingRegions);
  const regionOptions = useSelector(getRegionOptions);

  const loadingRegionsState = () => {
    const regionTerm = getRegionTerm();
    if (loadingRegions) return `Loading ${regionTerm}s...`;
    if (selectMultiple) return `Select Multiple ${regionTerm}s`;
    if (useAll) return `All ${regionTerm}s`;
    return regionTerm;
  };

  useEffect(() => {
    setPermittedRegionOptions(useAll ? [{ label: "All Regions", value: "all" }] : regionOptions);
  }, [loadingUser, userRegion, regionOptions]);

  // handle controlled value
  useEffect(() => {
    if (value && selectMultiple) {
      setSelectedRegions(selectOptionFromExtraField(regionOptions, "_id"));
    }
    if (value && !selectMultiple) setSelectedRegion(selectOptionFromValue(regionOptions, value));
  }, [value, userRegion, loadingUser, loadingRegions]);

  useEffect(() => {
    if (regionOptions.length === 0) dispatch(getAllRegions());
  }, []);

  return (
    <Select
      name={name}
      options={permittedRegionOptions}
      value={selectMultiple ? selectedRegions : selectedRegion}
      onChange={_handleSelect}
      isDisabled={disabled || loadingRegions || useAll || isDisabled || false}
      placeholder={loadingRegionsState()}
      loading={loadingRegions}
      defaultValue={defaultValue || undefined}
      isMulti={selectMultiple}
      label={label}
      required={required}
      colorScheme={colorScheme ? colorScheme : selectConstants.colorSchemes.BLACK}
    />
  );

  async function _handleSelect(selectedValue) {
    if (_.isFunction(onChange) && selectMultiple) {
      setSelectedRegions(selectedValue);
      onChange(selectedValue.map(val => val._id)); // return list of Mongo Ids
      return;
    }

    setSelectedRegion(selectOptionFromValue(regionOptions, selectedValue));
    if (_.isFunction(onChange)) onChange(selectedValue);
  }
};
RegionSelect.propTypes = regionSelectPropTypes;

export { RegionSelect };
